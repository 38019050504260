import { requestHeader } from "@/courses/services/GenericService.js";
import {
  courseAssignmentsDetailsBaseUrl,
  gradeDeleteAPIUrl,
  getAssignmentGradesUrl,
  putQuickGradesUrl,
} from "@/courses/urls.js";

const assignmentBase = courseAssignmentsDetailsBaseUrl.match(location.pathname);

export const assignmentModule = {
  namespaced: true,
  state: {
    assignmentId:
      assignmentBase && !isNaN(assignmentBase.assignmentId)
        ? assignmentBase.assignmentId
        : null,
    currentAssignment: {
      id: null,
      name: "",
      pass_fail: null,
      full_points: null,
      score_levels: [],
      grades: [],
    },
    quickGradeErrors: [],
  },
  actions: {
    editGrade: function ({ commit, state }, newGrade) {
      state.currentAssignment.pass_fail
        ? commit("editPassFailStatus", newGrade)
        : commit("editRawPoints", newGrade);
    },
    getQuickGrades: async function ({ commit }, { courseId, assignmentId }) {
      const url = getAssignmentGradesUrl.stringify({
        courseId,
        assignmentId,
      });
      const resp = await fetch(url);
      if (!resp.ok) {
        console.error(resp);
        throw resp;
      }
      const json = await resp.json();
      commit("addCurrentAssignment", json.assignment);
      commit("addQuickGrades", json.grades);
      return json;
    },
    updateQuickGrades: async function (
      { commit, state },
      { courseId, grades, submitSettings },
    ) {
      commit("removeSuccessAlert", null, { root: true });
      const url = putQuickGradesUrl.stringify({
        courseId: courseId,
        assignmentId: state.currentAssignment.id,
      });
      const resp = await fetch(url, {
        method: "PUT",
        headers: requestHeader,
        credentials: "same-origin",
        body: JSON.stringify({
          grades: grades,
          complete: submitSettings.isComplete,
          student_visible: submitSettings.studentVisible,
        }),
      });
      if (!resp.ok) {
        console.error(resp.status);
        let message = "Failed to save changes";
        commit("addErrorAlertMessage", message, { root: true });
        return false;
      }
      const json = await resp.json();
      grades.forEach((grade, i) => (grade.id = json.grade_ids[i]));
      grades.forEach((grade) => commit("putGradeInProgress", grade.id));

      // Clicked Publish button
      if (submitSettings.isComplete && submitSettings.studentVisible) {
        let message = "Grades Published, visible to students";
        commit("addSuccessAlertMessage", message, { root: true });
        return true;
      }
      // Clicked Submit button
      if (submitSettings.isComplete && !submitSettings.studentVisible) {
        let message = "Grades Submitted, not visible to students";
        commit("addSuccessAlertMessage", message, { root: true });
        return true;
      }
      // Autosave or clicked Save
      let message = "Changes saved";
      commit("addSuccessAlertMessage", message, { root: true });
      return true;
    },
    deleteGrade: async function ({ commit }, gradeID) {
      const url = gradeDeleteAPIUrl.stringify({ gradeId: gradeID });
      const resp = await fetch(url, {
        method: "DELETE",
        headers: requestHeader,
        credentials: "same-origin",
        body: JSON.stringify(gradeID),
      });
      if (!resp.ok) {
        console.error(resp);
        const message = "Failed to delete grade";
        commit("addErrorAlertMessage", message, { root: true });
        throw resp;
      }
      commit("deleteGrade", gradeID);
      const message = "Successfully deleted grade";
      commit("addSuccessAlertMessage", message, { root: true });
    },
  },
  mutations: {
    setQuickGradeError(state, { gradeId, isValid }) {
      const index = state.quickGradeErrors.indexOf(gradeId);
      if (isValid && index !== -1) {
        state.quickGradeErrors.splice(index, 1);
      } else if (!isValid && index === -1) {
        state.quickGradeErrors.push(gradeId);
      }
    },
    putGradeInProgress(state, id) {
      let grade = state.currentAssignment.grades.find((g) => g.id === id);
      grade.status = "In progress";
    },
    editRawPoints(state, newGrade) {
      state.currentAssignment.grades.forEach((grade, i, arr) => {
        if (grade.student_id === newGrade.student_id) {
          arr[i].raw_points =
            newGrade.outcome === "" ? null : Number(newGrade.outcome);
        }
      });
    },
    editPassFailStatus(state, newGrade) {
      state.currentAssignment.grades.forEach((grade, i, arr) => {
        if (grade.student_id === newGrade.student_id) {
          arr[i].pass_fail_status =
            newGrade.outcome === "" ? null : newGrade.outcome;
        }
      });
    },
    addCurrentAssignment(state, assignment) {
      state.currentAssignment = assignment;
    },
    addQuickGrades(state, grades) {
      state.currentAssignment["grades"] = grades;
    },
    deleteGrade(state, gradeID) {
      let deletedGrade = state.currentAssignment.grades.find(
        (grade) => grade.id === gradeID,
      );
      deletedGrade.id = null;
      deletedGrade.raw_points = null;
      deletedGrade.pass_fail_status = null;
      deletedGrade.status = "Ungraded";
    },
  },
};
