/* eslint-disable vue/one-component-per-file */
import "core-js/stable";
import "regenerator-runtime/runtime";

import { createApp, defineAsyncComponent } from "vue";
import { createPinia } from "pinia";
import { formatDatesMixin } from "../vue/courses/mixins/formatDates.js";
import { clickOutside } from "../vue/courses/utilities/ClickOutside.js";
import { pluralize, formatNum } from "../vue/courses/utilities/base.js";
import store from "../vue/courses/vue-store.js";

// outside components
import "@vue-a11y/announcer/dist/style.css";
import VueAnnouncer from "@vue-a11y/announcer";
import "@vue-a11y/skip-to/dist/style.css";
import VueSkipTo from "@vue-a11y/skip-to";
import flatPickr from "vue-flatpickr-component";
import { FocusTrap } from "focus-trap-vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueScrollTo from "vue-scrollto";

// modal close behavior
import mitt from "mitt";
const emitter = mitt();

// formkit configs
import { plugin, defaultConfig } from "@formkit/vue";
import formkitConfig from "../vue/courses/formkit.config.js";

// for header and sidenav separate apps
import BaseComponent from "../vue/courses/components/structure/BaseComponent.vue";
import SideNav from "../vue/courses/components/navigation/SideNav.vue";

// prettier-ignore
const componentImports = {
  AdminCourses: () => import("../vue/courses/views/overview/AdminCourses.vue"),
  AdminIndex: () => import("../vue/courses/views/overview/AdminIndex.vue"),
  AdminInstitutions: () => import("../vue/courses/views/overview/AdminInstitutions.vue"),
  AdminUsers: () => import("../vue/courses/views/overview/AdminUsers.vue"),
  AdminJobsIndex: () => import("../vue/courses/views/admin_jobs/AdminJobsIndex.vue"),
  AdminJobDetail: () => import("../vue/courses/views/admin_jobs/AdminJobDetail.vue"),
  AssignmentAnalytics: () => import("../vue/courses/views/admin_analytics/AssignmentAnalytics.vue"),
  AssignmentCsvImport: () => import("../vue/courses/views/admin_assignment_csv_import/AssignmentCsvImport.vue"),
  NewAssignmentEdit: () => import("../vue/courses/views/admin_assignments/edit/AssignmentEdit.vue"),
  AssignmentImport: () => import("../vue/courses/views/admin_assignment_import/AssignmentImport.vue"),
  AssignmentTypeAnalytics: () => import("../vue/courses/views/admin_analytics/AssignmentTypeAnalytics.vue"),
  AttendanceMassEdit: () => import("../vue/courses/views/admin_attendance/MassEdit.vue"),
  AttendanceSetup: () => import("../vue/courses/views/admin_attendance/Setup.vue"),
  BadgesEdit: () => import("../vue/courses/views/admin_badges/BadgesEdit.vue"),
  BadgesIndexStaff: () => import("../vue/courses/views/admin_badges/BadgesIndexStaff.vue"),
  BadgesIndexStudents: () => import("../vue/courses/views/student_badges/BadgesIndexStudents.vue"),
  BaseComponent: () => import("../vue/courses/components/structure/BaseComponent.vue"),
  GradebookTable: () => import("../vue/courses/views/admin/GradebookTable.vue"),
  GradesImportAssignmentSelect: () => import("../vue/courses/views/admin_grade_import/GradesImportAssignmentSelect.vue"),
  GradesImportForm: () => import("../vue/courses/views/admin_grade_import/GradesImportForm.vue"),
  GradeSchemeEdit: () => import("../vue/courses/views/grade_scheme_elements/GradeSchemeEdit.vue"),
  GradeSchemeTable: () => import("../vue/courses/views/grade_scheme_elements/Table.vue"),
  GradingStatus: () => import("../vue/courses/views/admin_grading_status/GradingStatus.vue"),
  InstructorOverview: () => import("../vue/courses/views/overview/Instructor.vue"),
  PointsPlanner: () => import("../vue/courses/views/points_planner/PointsPlanner.vue"),
  QuickGradeGroup: () => import("../vue/courses/views/admin_quick_grade/QuickGradeGroup.vue"),
  QuickGradeIndividual: () => import("../vue/courses/views/admin_quick_grade/QuickGradeIndividual.vue"),
  SideNav: () => import("../vue/courses/components/navigation/SideNav.vue"),
  StaffLearningObjectiveCategoryCreate: () => import("../vue/courses/views/admin_learning_objectives/StaffLearningObjectiveCategoryCreate.vue"),
  StaffLearningObjectiveCategoryEdit: () => import("../vue/courses/views/admin_learning_objectives/StaffLearningObjectiveCategoryEdit.vue"),
  StaffLearningObjectivesIndex: () => import ("../vue/courses/views/admin_learning_objectives/StaffLearningObjectivesIndex.vue"),
  StaffLearningObjectivesEdit: () => import ("../vue/courses/views/admin_learning_objectives/StaffLearningObjectivesEdit.vue"),
  StaffLearningObjectivesNew: () => import ("../vue/courses/views/admin_learning_objectives/StaffLearningObjectivesNew.vue"),
  StudentAnalytics: () => import("../vue/courses/views/admin_analytics/StudentAnalytics.vue"),
  StudentDashboard: () => import("../vue/courses/views/student_dashboard/StudentDashboard.vue"),
  StudentOverview: () => import("../vue/courses/views/overview/Student.vue"),
  StudentRoster: () => import("../vue/courses/views/admin/StudentRoster.vue"),
  StudentLearningObjectivesIndex: () => import("../vue/courses/views/student_learning_objectives/StudentLearningObjectivesIndex.vue"),
  StudentLearningObjectivesDetail: () => import("../vue/courses/views/student_learning_objectives/StudentLearningObjectivesDetail.vue"),
  SubmissionTextarea: () => import("../vue/courses/views/student_submissions/SubmissionTextarea.vue"),
  UserImport: () => import ("../vue/courses/views/admin_user_import/UserImport.vue"),
};

const pinia = createPinia();

document.addEventListener("DOMContentLoaded", () => {
  const vueHeaderEl = document.querySelector("#vueHeader");
  const vueSidebarEl = document.querySelector("#vueSidebar");

  // For use with pages that display the header and sidebar
  // as separate vue components, instead of mounting them
  // inside the main app
  if (vueHeaderEl) {
    const vueHeader = createApp({ name: "VueHeaderRoot" });
    vueHeader
      .component("BaseComponent", BaseComponent)
      .component("FocusTrap", FocusTrap);
    vueHeader.directive("click-outside", clickOutside);
    vueHeader.use(store).use(VueSkipTo).use(VueAnnouncer).use(pinia);
    vueHeader.mount(vueHeaderEl);
  }

  if (vueSidebarEl) {
    const vueSidebar = createApp({
      name: "VueSidebarRoot",
    });
    vueSidebar.component("SideNav", SideNav).component("FocusTrap", FocusTrap);
    vueSidebar.directive("click-outside", clickOutside);
    vueSidebar.use(store).use(pinia);
    vueSidebar.config.globalProperties.emitter = emitter;
    // Wait to load the main app until the sidebar data is ready
    // to avoid poorly-ordered async data requests that result in
    // long page load times (happens when main app data requests
    // sneak in first and are lengthy):
    emitter.on("sidenav-loading-complete", () => {
      // console.log('Sidenav loading complete');
      mountMainApp();
    });
    vueSidebar.mount(vueSidebarEl);
  }

  // For pages that are more purely Vue (header and sidebar
  // either aren't used, or they're inside the main app)
  if (!vueHeaderEl && !vueSidebarEl) {
    mountMainApp();
  }
});

function mountMainApp() {
  const mainEl = document.querySelector("#app");
  if (mainEl) {
    let app = createApp({ name: "VueMainRoot" });
    app.component("FocusTrap", FocusTrap).component("flat-pickr", flatPickr);
    Object.entries(componentImports).forEach(
      ([componentName, componentImport]) => {
        const asyncComponent = defineAsyncComponent(componentImport);
        app.component(componentName, asyncComponent);
      },
    );

    app.directive("click-outside", clickOutside);

    app
      .use(store)
      .use(pinia)
      .use(VueAnnouncer)
      .use(VueScrollTo)
      .use(VueSkipTo)
      .use(CKEditor)
      .use(plugin, defaultConfig(formkitConfig));

    app.mixin(formatDatesMixin).mixin({
      methods: {
        pluralize,
        formatNum,
      },
    });
    app.config.globalProperties.emitter = emitter;

    app.mount(mainEl);
  }
}
