import Transform from "@/courses/utilities/transformAPIResponse.js";
import { apiClient, requestHeader } from "@/courses/services/GenericService.js";
import {
  importCourse,
  searchCourses,
} from "@/courses/services/CourseService.js";
import {
  courseArchiveAPIUrl,
  courseCopyUrl,
  courseUnarchiveAPIUrl,
  courseDeleteAPIUrl,
  dashboardAdminNewActivityAPIUrl,
  coursePublishAPIUrl,
  courseUnpublishAPIUrl,
} from "@/courses/urls.js";

export const coursesModule = {
  namespaced: true,
  state: {
    pageData: [],
    searching: false,
    limit: 10,
    searchInput: "",
    currentFilters: {
      has_paid: false,
      has_not_paid: false,
      published: false,
      unpublished: false,
      status: true,
      semester: [],
      year: [],
    },
    paginationMeta: {},
  },
  actions: {
    formulateURLSearchParams: ({ state, getters }, page) => {
      let params = {
        status: state.currentFilters.status,
        items: 10,
        page: page,
      };
      if (state.has_badges) {
        params["has_badges"] = true;
      }
      if (state.has_learning_objectives) {
        params["has_learning_objectives"] = true;
      }
      if (state.has_teams) {
        params["has_teams"] = true;
      }

      if (
        state.currentFilters.published &&
        state.currentFilters.published != state.currentFilters.unpublished
      ) {
        let publishedFilter = { published: true };
        params = Object.assign(params, publishedFilter);
      } else if (
        state.currentFilters.unpublished &&
        state.currentFilters.published != state.currentFilters.unpublished
      ) {
        let publishedFilter = { published: false };
        params = Object.assign(params, publishedFilter);
      }

      if (
        state.currentFilters.has_paid &&
        state.currentFilters.has_paid != state.currentFilters.has_not_paid
      ) {
        let paidFilter = { has_paid: true };
        params = Object.assign(params, paidFilter);
      } else if (
        state.currentFilters.has_not_paid &&
        state.currentFilters.has_paid != state.currentFilters.has_not_paid
      ) {
        let paidFilter = { has_paid: false };
        params = Object.assign(params, paidFilter);
      }

      let yearFilters = { year: state.currentFilters.year };
      state.currentFilters.year.length
        ? Object.assign(params, yearFilters)
        : null;

      let semesterFilters = { semester: getters.getSemesterData };
      state.currentFilters.semester.length
        ? Object.assign(params, semesterFilters)
        : null;

      let searchFilter = { search: state.searchInput };
      state.searchInput.length ? Object.assign(params, searchFilter) : null;

      return params;
    },
    processPageContext: async ({ commit, rootState, dispatch }, page = 1) => {
      let params = await dispatch("formulateURLSearchParams", page);
      try {
        const { data, status } = await searchCourses({
          courseId: rootState.course.courseId,
          params,
        });
        if (status != 200) {
          let failMessage = "Unable to retrieve requested data.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          commit("setPaginationMeta", data.meta);
          commit("setSearchStatus", false);
          commit("setSelectedPageContext", data.data);
        }
      } catch (error) {
        commit("addErrorAlertMessage", `Error: ${error}`, {
          root: true,
        });
      }
    },
    processEventContext: ({ dispatch, rootState }, course) => {
      if (rootState.user.admin && course.callpoint === "overview") {
        dispatch("getNewActivity");
      } else if (rootState.user.admin) {
        dispatch("processPageContext");
      } else {
        dispatch("getCourseMemberships", null, { root: true });
      }
    },
    deleteCourse: async ({ commit, dispatch }, { course }) => {
      const url = courseDeleteAPIUrl.stringify({ courseId: course.id });
      try {
        const response = await fetch(url, {
          method: "DELETE",
          headers: requestHeader,
          credentials: "same-origin",
          body: JSON.stringify({ id: course.id }),
        });
        if (!response.ok) {
          let failMessage = "There was an error deleting your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully deleted your course!",
            { root: true },
          );
          dispatch("processEventContext", course);
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    copyCourse: async ({ commit, dispatch }, { course }) => {
      const courseId = course.id;
      const url = courseCopyUrl.stringify();
      try {
        const { status } = await apiClient.post(url, course, {
          params: { id: courseId },
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
          },
        });
        if (status >= 200 && status < 300) {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully copied your course!",
            { root: true },
          );
          dispatch("processEventContext", course);
        } else {
          let failMessage = "There was an error copying your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
        commit("addErrorAlertMessageMoreInfo", error.response.data.errors, {
          root: true,
        });
      }
    },
    importCourse: async (
      { commit },
      { formData, selectedCourse, currentCourse },
    ) => {
      try {
        let status = await importCourse(formData);
        if (status >= 200 && status < 300) {
          commit(
            "addSuccessAlertMessage",
            `Imported ${selectedCourse} into ${currentCourse}`,
            { root: true },
          );
        }
      } catch (error) {
        let failMessage = `${error}`;
        commit("addErrorAlertMessage", failMessage, { root: true });
      }
    },
    copyCourseWithStudents: async ({ commit, dispatch }, { course }) => {
      const courseId = course.id;
      const url = courseCopyUrl.stringify();
      try {
        const { status } = await apiClient.post(url, course, {
          params: { id: courseId, copy_type: "with_students" },
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
          },
        });
        if (status >= 200 && status < 300) {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully copied your course!",
            { root: true },
          );
          dispatch("processEventContext", course);
        } else {
          let failMessage = "There was an error copying your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    archiveCourse: async ({ commit, dispatch, rootState }, courseID) => {
      try {
        const url = courseArchiveAPIUrl.stringify();
        const response = await fetch(url, {
          method: "POST",
          headers: requestHeader,
          credentials: "same-origin",
          body: JSON.stringify({ courseID: courseID }),
        });
        if (!response.ok) {
          let failMessage = "There was an error archiving your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully archived your course!",
            { root: true },
          );
          rootState.user.admin
            ? dispatch("processPageContext")
            : dispatch("getCourseMemberships", null, { root: true });
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    unarchiveCourse: async ({ commit, dispatch }, courseID) => {
      try {
        const url = courseUnarchiveAPIUrl.stringify();
        const response = await fetch(url, {
          method: "POST",
          headers: requestHeader,
          credentials: "same-origin",
          body: JSON.stringify({ courseID: courseID }),
        });
        if (!response.ok) {
          let failMessage = "There was an error unarchiving your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          dispatch("processPageContext");
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully unarchived your course!",
            { root: true },
          );
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    unpublishCourse: async ({ commit, dispatch, rootState }, courseID) => {
      try {
        const url = courseUnpublishAPIUrl.stringify();
        const response = await fetch(url, {
          method: "POST",
          headers: requestHeader,
          credentials: "same-origin",
          body: JSON.stringify({ courseID: courseID }),
        });
        if (!response.ok) {
          let failMessage = "There was an error unpublishing your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully unpublished your course!",
            { root: true },
          );
        }
        rootState.user.admin
          ? dispatch("processPageContext")
          : dispatch("getCourseMemberships", null, { root: true });
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    publishCourse: async ({ commit, dispatch, rootState }, courseID) => {
      try {
        const url = coursePublishAPIUrl.stringify();
        const response = await fetch(url, {
          method: "POST",
          headers: requestHeader,
          credentials: "same-origin",
          body: JSON.stringify({ courseID: courseID }),
        });
        if (!response.ok) {
          let failMessage = "There was an error unpublishing your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully published your course!",
            { root: true },
          );
        }
        rootState.user.admin
          ? dispatch("processPageContext")
          : dispatch("getCourseMemberships", null, { root: true });
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    getNewActivity: async ({ commit }) => {
      try {
        const url = dashboardAdminNewActivityAPIUrl.stringify();
        const response = await fetch(url);
        if (!response.ok) {
          let failMessage = "There was an error updating the dashboard.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          const json = await response.json();
          let newInstructorsCount = json.instructors_count;
          let paidCoursesCount = json.paid_courses_count;
          let newSubscriptionsCount = json.subscriptions_count;
          const courses = Transform.apiResponseToData(json);
          commit(
            "addNewActivity",
            {
              courses,
              newSubscriptionsCount,
              newInstructorsCount,
              paidCoursesCount,
            },
            { root: true },
          );
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
  },
  mutations: {
    setFilterYear(state, selectedYear) {
      state.currentFilters.year = selectedYear;
    },
    setFilterTerm(state, selectedFilter) {
      state.currentFilters.semester = selectedFilter;
    },
    setShowSubscribed(state, boolean) {
      state.currentFilters.has_paid = boolean;
    },
    setShowUnsubscribed(state, boolean) {
      state.currentFilters.has_not_paid = boolean;
    },
    setShowPublished(state, boolean) {
      state.currentFilters.published = boolean;
    },
    setShowUnpublished(state, boolean) {
      state.currentFilters.unpublished = boolean;
    },
    setSelectedPageContext(state, courseData) {
      state.pageData = courseData;
    },
    setSearchStatus(state, value) {
      state.searching = value;
    },
    setSearchInput(state, input) {
      state.searchInput = input;
    },
    setTabStatus(state, selectedTabValue) {
      state.currentFilters.status = selectedTabValue;
    },
    setPaginationMeta(state, meta) {
      state.paginationMeta = meta;
    },
    setHasBadges(state, value) {
      state.has_badges = value;
    },
    setHasLearningObjectives(state, value) {
      state.has_learning_objectives = value;
    },
    setHasTeams(state, value) {
      state.has_teams = value;
    },
  },
  getters: {
    getSemesterData: (state) => {
      return state.currentFilters.semester;
    },
    getYearData: (state) => {
      return state.currentFilters.year;
    },

    getSearchStatus: (state) => {
      return state.searching;
    },
    perPageLimit: (state) => {
      return state.limit;
    },
    getSearchInput: (state) => {
      return state.searchInput;
    },
  },
};
