import UrlPattern from "url-pattern";

export const courseBaseUrl = new UrlPattern("/courses/:courseId(/*)");

export const newBaseUrl = new UrlPattern("/courses/:courseId/:editPageType/new")
export const editBaseUrl = new UrlPattern('/courses/:courseId/:editPageType/:itemId/edit')

// INTERFACE URLS //
export const courseBadgesDetailBaseUrl = new UrlPattern("/courses/:courseId/badges/:badgeId(/*)");
export const courseBadgesListPageUrl = new UrlPattern("/courses/:courseId/badges");
export const courseBadgesCreatePageUrl = new UrlPattern("/courses/:courseId/badges/new");
export const courseBadgesDetailPageUrl = new UrlPattern("/courses/:courseId/badges/:badgeId");
export const courseBadgesEditPageUrl = new UrlPattern("/courses/:courseId/badges/:badgeId/edit");
export const courseBadgesQuickAwardPageUrl = new UrlPattern("/courses/:courseId/badges/:badgeId/earned_badges/mass_edit");
export const courseBadgesAwardPageUrl = new UrlPattern("/courses/:courseId/badges/:badgeId/earned_badges/new");

export const courseBadgePageUrls = {
  list: courseBadgesListPageUrl,
  create: courseBadgesCreatePageUrl,
  details: courseBadgesDetailPageUrl,
  edit: courseBadgesEditPageUrl,
  quickAward: courseBadgesQuickAwardPageUrl,
  award: courseBadgesAwardPageUrl,
};

// Admin Overview
export const overviewUrl = new UrlPattern("/overview");
export const overviewCoursesUrl = new UrlPattern("/overview/courses");
export const overviewUsersUrl = new UrlPattern("/overview/users");
export const overviewSubscriptionsUrl = new UrlPattern("/overview/subscriptions");
export const overviewInstitutionsUrl = new UrlPattern("/overview/institutions");
export const overviewInstitutionsNewUrl = new UrlPattern("/overview/institutions/new");

// Overview
export const courseDashboardUrl = new UrlPattern("/courses/:courseId/dashboard");
export const courseGradingStatusUrl = new UrlPattern("/courses/:courseId/grading_status");
export const courseAnnouncementListUrl = new UrlPattern("/courses/:courseId/announcements");
export const courseAnnouncementDetailUrl = new UrlPattern("/courses/:courseId/announcements/:announcementId");
export const courseEventsUrl = new UrlPattern("/courses/:courseId/events");

// Analytics Urls
export const courseAnalyticsStudentsUrl = new UrlPattern("/courses/:courseId/analytics/students");
export const courseAnalyticsAssignmentsUrl = new UrlPattern("/courses/:courseId/analytics");
export const courseAnalyticsAssignmentTypesUrl = new UrlPattern("/courses/:courseId/analytics/assignment_types");
export const courseAnalyticsExportDataUrl = new UrlPattern("/courses/:courseId/assignments/:assignmentId/grades/export.csv");

export const analyticsUrls = {
  assignments: courseAnalyticsAssignmentsUrl,
  students: courseAnalyticsStudentsUrl,
  assignmentTypes: courseAnalyticsAssignmentTypesUrl,
};
export const courseDataExportsUrl = new UrlPattern("/courses/:courseId/downloads");

// Setup
export const courseEditUrl = new UrlPattern("/courses/:courseId/edit"); // General settings
export const courseAssignmentsSettingsUrl = new UrlPattern("/courses/:courseId/assignments/settings");
export const courseLearningObjectivesStaffIndexUrl = new UrlPattern("/courses/:courseId/learning_objectives/objectives");
export const courseLearningObjectivesStaffNewUrl = new UrlPattern("/courses/:courseId/learning_objectives/objectives/new");
export const courseLearningObjectivesStaffEditUrl = new UrlPattern("/courses/:courseId/learning_objectives/objectives/:learningObjectiveId/edit");
export const courseLearningObjectivesStaffCategoryNewUrl = new UrlPattern("/courses/:courseId/learning_objectives/categories/new");
export const courseLearningObjectivesStaffCategoryEditUrl = new UrlPattern("/courses/:courseId/learning_objectives/categories/:learningObjectiveCategoryId/edit");
export const courseLearningObjectivesStudentUrl = new UrlPattern("/courses/:courseId/learning_objectives/links");
export const courseChallengesUrl = new UrlPattern("/courses/:courseId/challenges");
export const courseChallengeDetailsUrl = new UrlPattern("/courses/:courseId/challenges/:challengeId");

export const courseGradeSchemeElementsUrl = new UrlPattern('/courses/:courseId/grade_scheme_elements')
export const courseGradeSchemeElementsEditUrl = new UrlPattern('/courses/:courseId/grade_scheme_elements/:unlock_condition_id/edit')

// Users
export const courseStudentsUrl = new UrlPattern("/courses/:courseId/students");
export const courseStudentDetailUrl = new UrlPattern("/courses/:courseId/students/:studentId");

export const courseTeamsUrl = new UrlPattern("/courses/:courseId/teams");
export const courseGroupsUrl = new UrlPattern("/courses/:courseId/groups"); //has counter
export const courseStaffUrl = new UrlPattern("/courses/:courseId/staff");
export const courseStaffDetailUrl = new UrlPattern("/courses/:courseId/staff/:staffId");

export const courseObserversUrl = new UrlPattern("/courses/:courseId/observers");

// Coursework
export const courseGradebookUrl = new UrlPattern("/courses/:courseId/gradebook");
export const courseAttendanceUrl = new UrlPattern("/courses/:courseId/attendance"); // automatically redirects to setup if none
export const courseAttendanceSetupUrl = new UrlPattern("/courses/:courseId/attendance/setup");
export const courseMultiplierChoicesUrl = new UrlPattern("/courses/:courseId/multiplier_choices");
export const coursePredictorUrl = new UrlPattern("/courses/:courseId/predictor");
export const courseEarnedBadgesUrl = new UrlPattern("/courses/:courseId/earned_badges");

// Assignments
export const courseAssignmentsUrl = new UrlPattern("/courses/:courseId/assignments");
export const courseAssignmentsDetailsBaseUrl = new UrlPattern("/courses/:courseId/assignments/:assignmentId(/*)");
export const courseAssignmentDetailUrl = new UrlPattern("/courses/:courseId/assignments/:assignmentId");
export const courseQuickGradeGroupUrl = new UrlPattern("/courses/:courseId/assignments/:assignmentId/groups/grades/mass_edit");
export const courseQuickGradeIndividualUrl = new UrlPattern("/courses/:courseId/assignments/:assignmentId/grades/quick_grade");
export const courseSubmissionDetailUrl = new UrlPattern("/courses/:courseId/assignments/:assignmentId/submissions/:submissionId");

// Admin (as seen in old sidebar)
export const courseNewUrl = new UrlPattern("/courses/new");
export const courseOverviewUrl = new UrlPattern("/courses/overview"); // Manage Courses
export const courseMembershipsUrl = new UrlPattern("/courses/:courseId/course_memberships");
export const institutionsUrl = new UrlPattern("/institutions");
export const courseUsersUrl = new UrlPattern("/courses/:courseId/users");
export const userSearchUrl = new UrlPattern("/users/search");
export const courseAdminToolsUrl = new UrlPattern("/courses/:courseId/admin/tools");
export const courseJobsUrl = new UrlPattern("/courses/:courseId/jobs");
export const courseJobsDetailUrl = new UrlPattern("/courses/:courseId/jobs/:jobId");

// Other
export const courseSyllabusUrl = new UrlPattern("/courses/:courseId/syllabus");
export const editUserProfileUrl = new UrlPattern("/users/edit_profile");
export const logoutUrl = new UrlPattern("/logout");
export const exitImpersonateUrl = new UrlPattern("/exit_student_impersonation");
export const courseLearningObjectivesDetailUrl = new UrlPattern("/courses/:courseId/learning_objectives/objectives/:objectiveId");
export const userImportOptionsUrl = new UrlPattern("/courses/:courseId/users/importers");
export const courseUsersNewUrl = new UrlPattern("/courses/:courseId/users/new");
export const courseTeamsDetailUrl = new UrlPattern("/courses/:courseId/teams/:teamId");

// API URLS //
export const courseSearchAPIUrl = new UrlPattern("/api/courses/search");
export const studentSearchAPIUrl = new UrlPattern("/api/students");
export const changeGuideAPIUrl = new UrlPattern("/api/users/change_guide");

export const assignmentTypeListAPIUrl = new UrlPattern("/api/assignment_types");

export const courseBadgesListAPIUrl = new UrlPattern("/api/courses/:courseId/badges");
export const courseBadgesCreateAPIUrl = new UrlPattern("/api/courses/:courseId/badges/create");
export const courseBadgesDetailAPIUrl = new UrlPattern("/api/courses/:courseId/badges/:badgeId");
export const courseBadgesUpdateAPIUrl = new UrlPattern("/api/courses/:courseId/badges/:badgeId/update");
export const courseBadgesDeleteAPIUrl = new UrlPattern("/api/courses/:courseId/badges/:badgeId/delete");
export const courseBadgesReorderAPIUrl = new UrlPattern("/api/courses/:courseId/badges/sort");

export const courseBadgeAPIUrls = {
  list: courseBadgesListAPIUrl,
  create: courseBadgesCreateAPIUrl,
  details: courseBadgesDetailAPIUrl,
  update: courseBadgesUpdateAPIUrl,
  delete: courseBadgesDeleteAPIUrl,
  reorder: courseBadgesReorderAPIUrl,
  options: new UrlPattern("/api/courses/:courseId/badges/options")
};

export const unlockConditionsCreateAPIUrl = new UrlPattern("/api/unlock_conditions");
export const unlockConditionsAPIUrl = new UrlPattern("/api/unlock_conditions/:unlockId");
export const unlockConditionsListAPIUrl = new UrlPattern("/api/:unlockableType/:unlockableId/unlock_conditions");

export const unlocksAPIUrls = {
  list: unlockConditionsListAPIUrl,
  create: unlockConditionsCreateAPIUrl,
  update: unlockConditionsAPIUrl,
  delete: unlockConditionsAPIUrl,
}

export const coursePointsPlannerAssignmentTypeListAPIUrl = new UrlPattern("/api/courses/:courseId/points_planner/assignment_types");
export const coursePointsPlannerAssignmentTypeWeightAPIUrl = new UrlPattern("/api/courses/:courseId/points_planner/assignment_type_weights");
export const coursePointsPlannerAssignmentTypeWeightDetailAPIUrl = new UrlPattern("/api/courses/:courseId/points_planner/assignment_type_weights/:assignmentTypeWeightId");
export const coursePointsPlannerBadgeListAPIUrl = new UrlPattern("/api/courses/:courseId/points_planner/badges");
export const coursePointsPlannerPredictedEarnedBadgeAPIUrl = new UrlPattern("/api/courses/:courseId/points_planner/badges/predicted_earned_badges");
export const coursePointsPlannerPredictedEarnedBadgeDetailAPIUrl = new UrlPattern("/api/courses/:courseId/points_planner/badges/predicted_earned_badges/:predictedEarnedBadgeId");
export const coursePointsPlannerPredictedEarnedGradeAPIUrl = new UrlPattern("/api/courses/:courseId/points_planner/predicted_earned_grades");
export const coursePointsPlannerPredictedEarnedGradeDetailAPIUrl = new UrlPattern("/api/courses/:courseId/points_planner/predicted_earned_grades/:predictedEarnedGradeId");
export const coursePointsPlannerChallengeListAPIUrl = new UrlPattern("/api/courses/:courseId/points_planner/challenges");
export const coursePointsPlannerPredictedEarnedChallengeAPIUrl = new UrlPattern("/api/courses/:courseId/points_planner/challenges/predicted_earned_challenges");
export const coursePointsPlannerPredictedEarnedChallengeDetailAPIUrl = new UrlPattern("/api/courses/:courseId/points_planner/challenges/predicted_earned_challenges/:predictedEarnedChallengeId");

export const coursePointsPlannerAPIUrls = {
  assignmentTypeList: coursePointsPlannerAssignmentTypeListAPIUrl,
  assignmentTypeWeightCreate: coursePointsPlannerAssignmentTypeWeightAPIUrl,
  assignmentTypeWeightDetail: coursePointsPlannerAssignmentTypeWeightDetailAPIUrl,
  badgeList: coursePointsPlannerBadgeListAPIUrl,
  challengeList: coursePointsPlannerChallengeListAPIUrl,
  predictedEarnedBadgeCreate: coursePointsPlannerPredictedEarnedBadgeAPIUrl,
  predictedEarnedBadgeDetail: coursePointsPlannerPredictedEarnedBadgeDetailAPIUrl,
  predictedEarnedGradeCreate: coursePointsPlannerPredictedEarnedGradeAPIUrl,
  predictedEarnedGradeDetail: coursePointsPlannerPredictedEarnedGradeDetailAPIUrl,
  predictedEarnedChallengeCreate: coursePointsPlannerPredictedEarnedChallengeAPIUrl,
  predictedEarnedChallengeDetail: coursePointsPlannerPredictedEarnedChallengeDetailAPIUrl,
};

export const ckEditorAPIURLs = {
  upload: "/api/upload_wysiwyg_images",
  view: "/api/download_wysiwyg_object/",
  delete: "/api/delete_wysiwyg_images",
};

export const courseCreationUrl = new UrlPattern("/api/course_creation");
export const getAnnouncementsCurrentUserUrl = new UrlPattern("/api/courses/:courseId/announcements/for_current_user");
export const getCurrentCourseUrl = new UrlPattern("/api/courses/:courseId");
export const getHeaderDataUrl = new UrlPattern("/api/courses/:courseId/header_data");
export const getNavigationCountsUrl = new UrlPattern("/api/courses/:courseId/navigation_counts");
export const getCurrentUserURL = new UrlPattern("/api/users/current");
export const studentLearningObjectiveDetailAssignmentsUrl = new UrlPattern("/api/courses/:courseId/learning_objectives/:objectiveId/assignments")

export const courseAnalyticsStudentsAPIUrl = new UrlPattern("/api/courses/:courseId/analytics/students");
export const courseAnalyticsAssignmentsAPIUrl = new UrlPattern("/api/courses/:courseId/analytics/assignments");
export const courseAnalyticsAssignmentTypesAPIUrl = new UrlPattern("/api/courses/:courseId/analytics/assignment_types");
export const courseAnalyticsAssignmentTypesStudentsAPIUrl = new UrlPattern("/api/courses/:courseId/analytics/assignment_types_students");
export const courseAnalyticsAPIUrls = {
  assignments: courseAnalyticsAssignmentsAPIUrl,
  assignmentTypes: courseAnalyticsAssignmentTypesAPIUrl,
  assignmentTypesStudents: courseAnalyticsAssignmentTypesStudentsAPIUrl,
  students: courseAnalyticsStudentsAPIUrl,
};

export const dashboardBadges = new UrlPattern("/api/courses/:courseId/dashboard/visible_badges");
export const courseLearningObjectivesListAPIUrl = new UrlPattern("/api/courses/:courseId/learning_objectives");
export const dashboardAssignmentsAllAPIUrl = new UrlPattern("/api/courses/:courseId/dashboard/assignments_by_status");
export const dashboardClassPointsAPIUrl = new UrlPattern("/api/courses/:courseId/dashboard/class_points");
export const dashboardGradeSchemeProgressAPIUrl = new UrlPattern("/api/courses/:courseId/dashboard/grade_scheme_progress");
export const dashboardMessageAPIUrl = new UrlPattern("/api/courses/:courseId/dashboard/message");
export const dashboardWeeklyStatsAPIUrl = new UrlPattern("/api/courses/weekly_stats");
export const dashboardTimelineAPIUrl = new UrlPattern("/api/timeline_events");
export const dashboardWeightsAPIUrl = new UrlPattern("/api/courses/:courseId/dashboard/weights");

export const courseDashboardAPIUrls = {
  analyticsPoints: dashboardClassPointsAPIUrl,
  weeklyStats: dashboardWeeklyStatsAPIUrl,
  assignmentsAll: dashboardAssignmentsAllAPIUrl,
  gradeSchemeProgress: dashboardGradeSchemeProgressAPIUrl,
  learningObjectives: courseLearningObjectivesListAPIUrl,
  message: dashboardMessageAPIUrl,
  timeline: dashboardTimelineAPIUrl,
  weights: dashboardWeightsAPIUrl,
};

// attendance.js
export const attendanceListCreateAPIUrl = new UrlPattern("/api/attendance");
export const attendanceDeleteAllAPIUrl = new UrlPattern("/api/attendance/delete_all");
export const attendanceUpdateDestroyAPIUrl = new UrlPattern("/api/attendance/:eventId");

// assignment.js
export const gradeDeleteAPIUrl = new UrlPattern("/api/grades/:gradeId");
export const getAssignmentAPIUrl = new UrlPattern("/api/assignments/:assignmentId");
export const getAssignmentGradesUrl = new UrlPattern("/api/courses/:courseId/assignments/:assignmentId/grades/quick_grade");
export const putQuickGradesUrl = new UrlPattern("/api/courses/:courseId/assignments/:assignmentId/grades/quick_update");
export const postGroupMassEditUrl = new UrlPattern('/courses/:courseId/assignments/:assignmentId/groups/grades/mass_update')

// courses.js
export const courseAdminOverviewAPIUrl = new UrlPattern("/api/courses/admin_overview");
export const courseArchiveAPIUrl = new UrlPattern("/api/courses/archive");
export const courseUnarchiveAPIUrl = new UrlPattern("/api/courses/unarchive");
export const courseDeleteAPIUrl = new UrlPattern("/api/courses/:courseId");
export const coursePublishAPIUrl = new UrlPattern("/api/courses/publish");
export const courseUnpublishAPIUrl = new UrlPattern("/api/courses/unpublish");
export const dashboardAdminNewActivityAPIUrl = new UrlPattern("/api/dashboard/admin_new_activity");

// gradeSchemeElements.js
export const gradeSchemeElementDeleteAllAPIUrl = new UrlPattern("/api/grade_scheme_elements/destroy_all");
export const gradeSchemeElementListUpdateAPIUrl = new UrlPattern("/api/grade_scheme_elements");

// gradingStatus.js
export const getGradesSubsetUrl = (idArray) => `/api/grading_status/grades/batch_grades?grade_ids=${idArray.join(",")}`;
export const getInProgressIdsUrl = new UrlPattern("/api/grading_status/grades/in_progress_ids");
export const getReleaseReadyIdsUrl = new UrlPattern("/api/grading_status/grades/ready_for_release_ids");
export const getResubmissionIdsUrl = new UrlPattern("/api/grading_status/submissions/resubmitted_ids");
export const getSubmissionSubsetUrl = (idArray) => `/api/grading_status/submissions/batch_submissions?submission_ids=${idArray.join(",")}`;
export const getUngradedIdsUrl = new UrlPattern("/api/grading_status/submissions/ungraded_ids");
export const postReleaseGradesURL = new UrlPattern("/api/courses/:courseId/assignments/grades/release");

// users.js
export const adminOverviewSearchUsersUrl = new UrlPattern("/api/users/admin_overview_search");
export const getInstructorsUrl = new UrlPattern("/api/users/instructors");

// Canvas
export const getCanvasGradesUrl = new UrlPattern("/api/assignments/:assignmentId/grades/importers/:providerName/course/:providerCourseId");
export const importCanvasGradesAssignmentSelectUrl = new UrlPattern("/courses/:courseId/assignments/:assignmentId/grades/importers/:providerName/assignments")
export const importCanvasGradesSelectUrl = new UrlPattern("/courses/:courseId/assignments/:assignmentId/grades/importers/:providerName/courses/:providerCourseId/grades");
export const importCanvasGradesUrl = new UrlPattern("/courses/:courseId/assignments/:assignmentId/grades/importers/:providerName/courses/:providerCourseId/grades/import")

// API V2 URLS //
const API_V2_ADMIN_BASE = "/api/v2/admin/courses/:courseId"
const API_V2_STUDENT_BASE = "/api/v2/students/courses/:courseId"

// Assignments
const ASSIGNMENTS_API_V2_ADMIN_BASE_URL = `${API_V2_ADMIN_BASE}/assignments`;
const ASSIGNMENTS_API_V2_RESOURCE_URL = `${ASSIGNMENTS_API_V2_ADMIN_BASE_URL}/:assignmentId`;
export const ASSIGNMENTS_API_V2_URLS = {
  index: new UrlPattern(ASSIGNMENTS_API_V2_ADMIN_BASE_URL),
  resource: new UrlPattern(ASSIGNMENTS_API_V2_RESOURCE_URL),
  uploads: new UrlPattern(`${ASSIGNMENTS_API_V2_RESOURCE_URL}/file_uploads`),
  fileRemoval: new UrlPattern(`${ASSIGNMENTS_API_V2_RESOURCE_URL}/file_removal`),
  options: new UrlPattern(`${ASSIGNMENTS_API_V2_ADMIN_BASE_URL}/options`),
}

export const ASSIGNMENT_TYPES_API_V2_URLS = {
  options: new UrlPattern(`${API_V2_ADMIN_BASE}/assignment_types/options`),
}

export const SUBMISSIONS_API_V2_URLS = {
  index: new UrlPattern(`${API_V2_STUDENT_BASE}/assignments/:assignmentId/submissions`),
  resource: new UrlPattern(`${API_V2_STUDENT_BASE}/assignments/:assignmentId/submissions/:submissionId`),
}

// Jobs
const JOBS_BASE_API_V2_URL = `${API_V2_ADMIN_BASE}/jobs`;
const JOBS_RESOURCE_API_V2_URL = `${API_V2_ADMIN_BASE}/jobs/:jobId`;
export const JOBS_API_V2_URLS = {
  index: new UrlPattern(JOBS_BASE_API_V2_URL),
  resource: new UrlPattern(JOBS_RESOURCE_API_V2_URL),
  poll: new UrlPattern(`${JOBS_RESOURCE_API_V2_URL}/poll`),
}

// Learning Objectives
const LEARNING_OBJECTIVES_API_V2_ADMIN_BASE_URL = `${API_V2_ADMIN_BASE}/learning_objectives`;
const LEARNING_OBJECTIVES_API_V2_RESOURCE_URL = `${LEARNING_OBJECTIVES_API_V2_ADMIN_BASE_URL}/:learningObjectiveId`;
export const LEARNING_OBJECTIVES_API_V2_URLS = {
  index: new UrlPattern(LEARNING_OBJECTIVES_API_V2_ADMIN_BASE_URL),
  resource: new UrlPattern(LEARNING_OBJECTIVES_API_V2_RESOURCE_URL),
  options: new UrlPattern(`${LEARNING_OBJECTIVES_API_V2_ADMIN_BASE_URL}/options`),

}

// Learning Objective Categories
const LEARNING_OBJECTIVE_CATEGORIES_API_V2_ADMIN_BASE_URL = `${API_V2_ADMIN_BASE}/learning_objective_categories`;
const LEARNING_OBJECTIVE_CATEGORIES_API_V2_RESOURCE_URL = `${LEARNING_OBJECTIVE_CATEGORIES_API_V2_ADMIN_BASE_URL}/:learningObjectiveCategoryId`;
export const LEARNING_OBJECTIVE_CATEGORIES_API_V2_URLS = {
  index: new UrlPattern(LEARNING_OBJECTIVE_CATEGORIES_API_V2_ADMIN_BASE_URL),
  resource: new UrlPattern(LEARNING_OBJECTIVE_CATEGORIES_API_V2_RESOURCE_URL),
}

// Notifications
const NOTIFICATIONS_API_V2_RESOURCE_URL = `${API_V2_ADMIN_BASE}/notifications/:notificationId`;
export const NOTIFICATIONS_API_V2_URLS = {
  resource: new UrlPattern(NOTIFICATIONS_API_V2_RESOURCE_URL),
}

// Rubrics
const RUBRICS_API_V2_ADMIN_BASE_URL = `${API_V2_ADMIN_BASE}/rubrics`;
const RUBRICS_API_V2_RESOURCE_URL = `${RUBRICS_API_V2_ADMIN_BASE_URL}/:rubricId`;
export const RUBRICS_API_V2_URLS = {
  resource: new UrlPattern(RUBRICS_API_V2_RESOURCE_URL),
  options: new UrlPattern(`${RUBRICS_API_V2_ADMIN_BASE_URL}/options`),
}

// vue-store.js
export const userFlagAPIUrl = new UrlPattern("/users/:userId/flag");
export const courseStudentsAPIUrl = new UrlPattern("/api/courses/:courseId/students");
export const gradebookAssignmentsListAPIUrl = new UrlPattern("/api/gradebook/assignments");
export const gradebookStudentIdsAPIUrl = new UrlPattern("/api/gradebook/student_ids");
export const userDetailAPIUrl = new UrlPattern("/api/users/:userId");
export const toggleCourseMembershipStatusAPIUrl = new UrlPattern("/api/course_memberships/delete_course_membership");
export const toggleStudentActivationStatusAPIUrl = new UrlPattern("/api/course_memberships/toggle_course_membership_activation");

export const courseCopyUrl = new UrlPattern("/api/courses/copy");
export const copyCourseWithStudentsUrl = new UrlPattern("/api/courses/copy?copy_type=with_students&id=:courseId");
export const setLockConditionsUrl = new UrlPattern("/courses/:courseId/grade_scheme_elements/:elementId/edit");

export const courseGroupQuickGradeAPIUrl = new UrlPattern("/api/courses/:courseId/assignment/:assignmentId/group_quick_grade");

export const providerImportFormActionUrl = new UrlPattern("/courses/:courseId/users/importers/:providerName/users/course/:providerCourseId/import");
export const assignmentProviderUploadSampleCSVUrl = new UrlPattern("/courses/:courseId/assignments/importers/:providerName/download.csv")
export const assignmentProviderUploadUrl = new UrlPattern("/api/v2/admin/courses/:courseId/assignments/importers/:providerName/upload");
export const assignmentProviderCSVImportUrl = new UrlPattern("/api/v2/admin/courses/:courseId/assignments/importers/:providerName/import");
export const assignmentProviderImportFormActionUrl = new UrlPattern("/courses/:courseId/assignments/importers/:providerName/courses/:providerCourseId/assignments/import");
export const providerAuthAPIUrl = new UrlPattern("/auth/:providerName");
export const providerCourseAssignmentListAPIUrl = new UrlPattern("/api/v2/admin/courses/:courseId/assignments/importers/:providerName/course/:providerCourseId/assignments");
export const providerCourseListAPIUrl = new UrlPattern("/api/courses/importers/:providerName/courses");
export const providerCourseUserListAPIUrl = new UrlPattern("/api/users/importers/:providerName/course/:providerCourseId/users");
